import { logUsage } from 'owa-analytics';
import { isCategoryInMasterList, lazySubscribeToCategoryNotifications } from 'owa-categories';
import type {
    FavoriteCategoryData,
    FavoriteFolderData,
    FavoriteGroupData,
    OutlookFavoriteKind,
    OutlookFavoriteServiceDataType,
} from 'owa-favorites-types';
import folderStore, { FolderTreeLoadStateEnum } from 'owa-folders';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import getFavorites from 'owa-session-store/lib/utils/getFavorites';
import {
    type FavoritesBitFlagsMasks,
    getIsBitEnabled,
} from '../actions/helpers/favoritesBitFlagsActions';
import setFavoritesLoaded from '../actions/setFavoritesLoaded';
import onFavoritesLoaded from '../actions/onFavoritesLoaded';
import { addFavoriteToStoreInitial } from '../actions/v2/addFavoriteActions';
import { addToOrderedFavoriteIdList } from '../actions/v2/addToOrderedFavoriteIdList';
import migrateOutlookFavorites from '../actions/v2/migrateOutlookFavorites';
import { isGroupInFavorites } from '../selectors/isInFavorites';
import isMultiAccountsCombinedFavoritesEnabled from './isMultiAccountsCombinedFavoritesEnabled';
import {
    lazyAddNewMailboxDefaultFavorites,
    lazySetOutlookFavoritesBitFlag,
} from '../lazyFunctions';
import getOutlookFavoritesService from '../services/v2/getOutlookFavoritesService';
import favoritesStore from '../store/store';
import { convertServiceResponseToFavoriteData } from '../utils/favoriteServiceDataUtils';
import type { MailboxInfo } from 'owa-client-types';
import { initializeFavoriteTreeData } from './initializeFavoriteTreeData';
import {
    getIndexerValueForMailboxInfo,
    isSameCoprincipalAccountMailboxAndIndexer,
} from 'owa-client-types';
import getCombinedFavoritesFromCache from '../utils/getCombinedFavoritesFromCache';
import setCombinedFavoritesInCache from '../utils/setCombinedFavoritesInCache';
import { isCapabilityEnabled } from 'owa-capabilities';
import { favoritesServiceCapability } from 'owa-capabilities-definitions/lib/favoritesServiceCapability';

const HOUR_TIMESPAN_IN_MS = 1000 * 60 * 60;

/**
 * Load outlook favorites
 */
export default async function loadOutlookFavorites(mailboxInfo: MailboxInfo) {
    const key = getIndexerValueForMailboxInfo(mailboxInfo);

    initializeFavoriteTreeData(key, mailboxInfo);

    const loadingState = favoritesStore?.favoriteTreeData.get(key)?.loadingState;
    if (
        loadingState === FolderTreeLoadStateEnum.Loading ||
        loadingState == FolderTreeLoadStateEnum.Loaded
    ) {
        return;
    }

    // Load UserConfiguration.Favorites if there is any
    // We want to load the Favorites to store first and then migrate, because migrateOutlookFavorites
    // will send multiple create service call and await for all of them succeeds, and we don't want to delay boot
    await loadOutlookFavoritesToStore(mailboxInfo);

    const firstRunCompleted = getIsBitEnabled(2);
    const roamingFavoritesMigrationCompleted = getIsBitEnabled(16);

    if (!roamingFavoritesMigrationCompleted) {
        // Migrate favorites if it hasn't completed
        migrateOutlookFavorites(mailboxInfo);
    }
    // Add default favorites for new users
    if (!firstRunCompleted) {
        // Only add default folders for new outlook users
        if (
            favoritesStore?.favoriteTreeData.get(key)?.orderedOutlookFavoritesIds.length == 0 &&
            isNewUser()
        ) {
            lazyAddNewMailboxDefaultFavorites.importAndExecute(mailboxInfo);
        }
        lazySetOutlookFavoritesBitFlag.importAndExecute(2);
    }
    logLoadOutlookFavoritesDatapoints();

    setFavoritesLoaded(key);
    onFavoritesLoaded(key);
}

export async function loadOutlookFavoritesToStore(mailboxInfo: MailboxInfo) {
    const key = getIndexerValueForMailboxInfo(mailboxInfo);
    initializeFavoriteTreeData(key, mailboxInfo);
    const loadingState = favoritesStore?.favoriteTreeData.get(key)?.loadingState;
    if (
        loadingState === FolderTreeLoadStateEnum.Loading ||
        loadingState == FolderTreeLoadStateEnum.Loaded
    ) {
        return;
    }

    // Use session data for logged in email address even when reload is true.
    // This is to avoid a service call when the data is available in the session store.
    let outlookFavorites = getFavorites(mailboxInfo)?.value;
    if (!outlookFavorites && isCapabilityEnabled(favoritesServiceCapability, mailboxInfo)) {
        // If there are no favorites in session data, retry using OWS' call
        let outlookFavoritesResponse;

        try {
            outlookFavoritesResponse = await getOutlookFavoritesService(mailboxInfo);
        } catch (error) {
            logUsage('GetOutlookFavoritesRetryFailed', {
                errorMessage: error.toString(),
            });
            return;
        }

        outlookFavorites = outlookFavoritesResponse?.value;

        if (!outlookFavorites) {
            return;
        }

        logUsage('FavoritesRetrySuccess');
    }

    const favoriteIds: string[] = [];
    // Populate favorites based on the OutlookFavorites
    (outlookFavorites as OutlookFavoriteServiceDataType[])?.forEach(
        (serviceData: OutlookFavoriteServiceDataType) => {
            const favoriteType = serviceData.Type?.toLocaleLowerCase() as OutlookFavoriteKind;
            try {
                const favoriteData = convertServiceResponseToFavoriteData(
                    serviceData,
                    favoriteType,
                    mailboxInfo
                );
                favoriteIds.push(favoriteData.favoriteId);
                switch (favoriteData.type) {
                    case 'folder':
                        const favoriteFolderData = favoriteData as FavoriteFolderData;
                        if (favoriteFolderData.isSearchFolder) {
                            addFavoriteToStoreInitial(favoriteData);
                        } else {
                            tryAddSingleFavoriteFolderToStore(
                                favoriteData as FavoriteFolderData,
                                serviceData.SingleValueSettings?.[0].Value ?? ''
                            );
                        }

                        break;

                    case 'persona':
                        addFavoriteToStoreInitial(favoriteData);
                        break;

                    case 'publicFolder':
                        addFavoriteToStoreInitial(favoriteData);
                        break;

                    case 'group':
                        tryAddSingleFavoriteGroupToStore(favoriteData as FavoriteGroupData);
                        break;

                    case 'category':
                        tryAddSingleFavoriteCategoryToStore(
                            favoriteData as FavoriteCategoryData,
                            mailboxInfo
                        );
                        break;

                    default:
                        // VSO 26085: Optimize updating the favorite position data
                        // For unknown type, we have to update orderedOutlookFavoritesIds in order to
                        // record the index, which is used to locally update the favorite store
                        addToOrderedFavoriteIdList(favoriteData);
                        break;
                }
            } catch (error) {
                // continue execution if a favorite could not be loaded
                logUsage('AddFavoriteToStoreInitialFailed', {
                    favoriteType,
                    errorMessage: error.toString(),
                });
            }
        }
    );

    // remove any deleted favorites from cache, if combined favorites flight is on
    if (isMultiAccountsCombinedFavoritesEnabled()) {
        const cachedFavoritesList = getCombinedFavoritesFromCache();

        if (cachedFavoritesList) {
            const curMailboxInfoIndexValue = getIndexerValueForMailboxInfo(mailboxInfo);
            const validFavorites = cachedFavoritesList.combinedFavorites.filter(
                favoriteFromCache => {
                    const isSameAccount = isSameCoprincipalAccountMailboxAndIndexer(
                        favoriteFromCache.mailboxInfo,
                        curMailboxInfoIndexValue
                    );

                    return (
                        !isSameAccount ||
                        (isSameAccount && favoriteIds.indexOf(favoriteFromCache.favoriteId) >= 0)
                    );
                }
            );

            if (validFavorites) {
                favoritesStore.orderedCombinedOutlookFavoritesIds = validFavorites.map(
                    favorite => favorite.favoriteId
                );
                setCombinedFavoritesInCache({ combinedFavorites: validFavorites });
            }
        }
    }
}

/**
 * Add single favorite folder
 * @param favoriteData the favorite folder data to be added
 */
function tryAddSingleFavoriteFolderToStore(favoriteData: FavoriteFolderData, restFolderId: string) {
    if (shouldAddFavoriteFolder(restFolderId)) {
        addFavoriteToStoreInitial(favoriteData);
    }
}

/**
 * Should add folder to favorite list
 * @param restFolderId the rest folder id to add to favorites
 * @param ewsFolderId the ews folder id to add to favorites
 * @param state the LoadFavoritesState which contains favorites store, folder table
 */
function shouldAddFavoriteFolder(restFolderId: string): boolean {
    if (folderStore.folderTable.size === 0) {
        // Do not add the folder if the folder store hasn't been initiated yet
        return false;
    }

    // Add the folders if the folder is not in favorites yet. This could happen during firstRun, if user already has the default favorite folder.
    // We don't check to see if the folder exists here, because it could just not be loaded yet. We try to fetch unloaded folders in onOutlookFavoritesV2Loaded
    return !favoritesStore.outlookFavorites.has(restFolderId);
}

/**
 * Add single favorite category
 * @param favoriteData the favorite category data to be added
 */
function tryAddSingleFavoriteCategoryToStore(
    favoriteData: FavoriteCategoryData,
    mailboxInfo: MailboxInfo
) {
    if (shouldAddFavoriteCategory(favoriteData, mailboxInfo)) {
        addFavoriteToStoreInitial(favoriteData);
        lazySubscribeToCategoryNotifications.importAndExecute(mailboxInfo); // This call makes sure that we only subscribe once, still need it here because this could be the first fav category added.
    }
}

/**
 * Should add folder to favorite list
 * @param favoriteData the favorite category data
 * @return a boolean which indicates whether we should add a favorite category
 */
function shouldAddFavoriteCategory(
    favoriteData: FavoriteCategoryData,
    mailboxInfo: MailboxInfo
): boolean {
    // Add the category to favorites if it is still valid and it is not favorited
    // Otherwise skip adding it, e.g the category has been deleted by the user from other client
    return (
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        isCategoryInMasterList(favoriteData.categoryId, mailboxInfo!) &&
        !favoritesStore.outlookFavorites.has(favoriteData.favoriteId)
    );
}

/**
 * Add single favorite group
 * @param favoriteData the favorite group data to be added
 * @param favoritesStore which contains all favorite data
 */
function tryAddSingleFavoriteGroupToStore(favoriteData: FavoriteGroupData) {
    if (!isGroupInFavorites(favoriteData.groupId)) {
        addFavoriteToStoreInitial(favoriteData);
    }
}

function logLoadOutlookFavoritesDatapoints() {
    const allFavorites = [...favoritesStore.outlookFavorites.values()];

    const outlookFavoriteFolders = allFavorites.filter(favorite => favorite.type === 'folder');

    const migratedFolders = outlookFavoriteFolders.filter(
        favorite => favorite.client === 'Migration'
    );

    const outlookFavoriteCategories = allFavorites.filter(favorite => favorite.type === 'category');

    const migratedCategories = outlookFavoriteCategories.filter(
        favorite => favorite.client == 'Migration'
    );

    const outlookFavoritePersonas = allFavorites.filter(favorite => favorite.type === 'persona');

    const migratedPersonas = outlookFavoritePersonas.filter(
        favorite => favorite.client == 'Migration'
    );

    const outlookFavoriteGroups = allFavorites.filter(favorite => favorite.type === 'group');

    logUsage('LoadOutlookFavorites', [
        outlookFavoriteFolders.length,
        outlookFavoriteGroups.length,
        outlookFavoriteCategories.length,
        outlookFavoritePersonas.length,
        migratedFolders.length,
        migratedCategories.length,
        migratedPersonas.length,
    ]);
}

// Returns true if the user's mailbox has been created within the last hour
function isNewUser() {
    const createDate = new Date(getUserConfiguration()?.MailboxCreateDate ?? Date.now());
    return Date.now() - createDate.getTime() < HOUR_TIMESPAN_IN_MS;
}
