import favoritesStore from '../store/store';
import { mutator } from 'satcheljs';
import setFavoritesLoaded from '../actions/setFavoritesLoaded';
import { FolderTreeLoadStateEnum } from 'owa-folders';
import { isFeatureEnabled } from 'owa-feature-flags';

/* eslint-disable-next-line owa-custom-rules/prefer-mutator-action -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Please use a mutatorAction if action is defined in the same package */
export default mutator(setFavoritesLoaded, actionMessage => {
    if (isFeatureEnabled('tri-favorites-roaming')) {
        const favoriteTreeDataLoadingState = favoritesStore.favoriteTreeData.get(
            actionMessage.mailboxKey
        );
        if (favoriteTreeDataLoadingState) {
            favoriteTreeDataLoadingState.loadingState = FolderTreeLoadStateEnum.Loaded;
        }
    } else {
        // Favorites V1 state
        favoritesStore.favoritesLoaded = true;
    }
});
