import { convertRestFolderIdToEWSFolderId } from './ewsRestFolderIdConverter';
import type {
    OutlookFavoriteKind,
    OutlookFavoriteServiceDataType,
    FavoriteData,
    FavoriteFolderData,
    FavoritePublicFolderData,
    FavoritePersonaData,
    FavoriteGroupData,
    FavoriteCategoryData,
    FavoriteDataClient,
    FavoriteSearchData,
} from 'owa-favorites-types';
import type { MailboxInfo } from 'owa-client-types';

export const SEARCH_FOLDER_ID_KEY = 'SearchFolderId';
export const PERSONA_ID_KEY = 'PersonaId';
export const MAIN_EMAIL_ADDRESS_KEY = 'MainEmailAddress';
export const EMAIL_ADDRESSES_KEY = 'EmailAddresses';
export const CONTACT_DISPLAYNAME_KEY = 'ContactDisplayName';
export const CONTACT_EMAIL_ADDRESS_KEY = 'ContactMainEmailAddress';
export const MEMBER_NAMES_KEY = 'MemberNames';
export const GROUP_SMTP_KEY = 'EmailAddress';
export const GROUP_EXTERNAL_DIRCTORY_OBJECT_ID_KEY = 'ExtDirId';

/**
 * Retrieves the single value setting for the provided key from the provided serviceData object
 * or undefined if the key is not found
 * @param serviceData the serviceData object
 * @param key the key
 */
export function getSingleValueSettingValueForKey(
    serviceData: OutlookFavoriteServiceDataType,
    key: string
): string | undefined {
    return serviceData.SingleValueSettings?.filter(data => data.Key == key)?.[0]?.Value;
}

/**
 * Retrieves the multi value setting for the provided key from the provided serviceData object
 * or [] if the key is not found
 * @param serviceData the serviceData object
 * @param key the key
 */
export function getMultiValueSettingValueForKey(
    serviceData: OutlookFavoriteServiceDataType,
    key: string
): string[] {
    return serviceData.MultiValueSettings?.filter(data => data.Key == key)?.[0]?.Values ?? [];
}

export function convertServiceResponseToFavoriteData(
    serviceData: OutlookFavoriteServiceDataType,
    favoriteKind: OutlookFavoriteKind,
    mailboxInfo: MailboxInfo
): FavoriteData {
    let favoriteData;
    switch (favoriteKind) {
        case 'folder':
        case 'publicFolder':
            const isPublicFolder = getSingleValueSettingValueForKey(serviceData, 'IsPublicFolder');
            // We do both checks since the type is saved as folder
            // but a public folder would have the property set
            if (favoriteKind == 'publicFolder' || isPublicFolder) {
                const favoritePublicFolderData: Partial<FavoritePublicFolderData> = {
                    type: 'publicFolder',
                    publicFolderId: getSingleValueSettingValueForKey(serviceData, 'FolderId'),
                };

                favoriteData = favoritePublicFolderData;
            } else {
                const favoriteFolderData: Partial<FavoriteFolderData> = {
                    type: 'folder',
                    folderId: convertRestFolderIdToEWSFolderId(
                        serviceData.SingleValueSettings?.[0].Value ?? ''
                    ),
                };

                favoriteFolderData.isSearchFolder = !!getSingleValueSettingValueForKey(
                    serviceData,
                    'IsSearchFolder'
                );
                favoriteData = favoriteFolderData;
            }

            break;

        case 'persona':
            const searchFolderId = getSingleValueSettingValueForKey(
                serviceData,
                SEARCH_FOLDER_ID_KEY
            );

            // Conversion necessary because OWA uses OWS (and EWS Ids) for managing folders
            const convertedSearchFolderId = searchFolderId
                ? convertRestFolderIdToEWSFolderId(searchFolderId)
                : undefined;

            const allEmailAddresses = getMultiValueSettingValueForKey(
                serviceData,
                EMAIL_ADDRESSES_KEY
            );
            const mailEmailAddress = getSingleValueSettingValueForKey(
                serviceData,
                MAIN_EMAIL_ADDRESS_KEY
            );
            const personaId = getSingleValueSettingValueForKey(serviceData, PERSONA_ID_KEY);

            const contactDisplayName = getSingleValueSettingValueForKey(
                serviceData,
                CONTACT_DISPLAYNAME_KEY
            );
            const contactMainEmailAddress = getSingleValueSettingValueForKey(
                serviceData,
                CONTACT_EMAIL_ADDRESS_KEY
            );

            const favoritePersonaData: Partial<FavoritePersonaData> = {
                type: 'persona',
                searchFolderId: convertedSearchFolderId,
                allEmailAddresses,
                mainEmailAddress: mailEmailAddress,
                isSearchFolderPopulated: !!convertedSearchFolderId,
                personaId,
                contactDisplayName,
                contactMainEmailAddress,
                isSyncUpdateDone: false,
            };
            favoriteData = favoritePersonaData;
            break;

        case 'group':
            const favoriteGroupData: Partial<FavoriteGroupData> = {
                type: 'group',
                groupId: serviceData.SingleValueSettings?.[0].Value.toLowerCase(),
                groupSmtp: getSingleValueSettingValueForKey(
                    serviceData,
                    GROUP_SMTP_KEY
                )?.toLowerCase(),
                groupExternalDirectoryObjectId: getSingleValueSettingValueForKey(
                    serviceData,
                    GROUP_EXTERNAL_DIRCTORY_OBJECT_ID_KEY
                ),
            };

            favoriteData = favoriteGroupData;
            break;

        case 'category':
            const favoriteCategoryData: Partial<FavoriteCategoryData> = {
                type: 'category',
                categoryId: serviceData.SingleValueSettings?.[0].Value,
            };

            favoriteData = favoriteCategoryData;
            break;

        case 'search':
            const favoriteSearchData: Partial<FavoriteSearchData> = {
                type: 'search',
                searchQuery: serviceData.SingleValueSettings?.[0].Value,
            };

            favoriteData = favoriteSearchData;
            break;

        default:
            const favoriteDataLocal: Partial<FavoriteData> = {
                type: serviceData.Type,
            };

            favoriteData = favoriteDataLocal;
            break;
    }

    favoriteData.treeType = 'favorites';
    favoriteData.favoriteId = serviceData.Id;
    favoriteData.displayName = serviceData.DisplayName;
    favoriteData.client = serviceData.Client as FavoriteDataClient;
    favoriteData.lastModifiedTime = serviceData.LastModifiedTime;
    favoriteData.mailboxInfo = mailboxInfo;

    return favoriteData as FavoriteData;
}
