import type { FavoriteData } from 'owa-favorites-types';
import moveDragAndDroppableItem from 'owa-dnd/lib/utils/moveDragAndDroppableItem';
import setCombinedFavoritesInCache from '../../utils/setCombinedFavoritesInCache';
import getCombinedFavoritesFromCache from '../../utils/getCombinedFavoritesFromCache';
import type { CombinedFavoritesList } from '../../store/schema/CombinedFavoritesList';

export function isFavoriteInCache(favoriteId: string): boolean {
    const combinedList: CombinedFavoritesList | undefined = getCombinedFavoritesFromCache();

    if (!combinedList) {
        return false;
    }

    return combinedList.combinedFavorites.filter(fav => fav.favoriteId === favoriteId).length > 0;
}

export function removeFavoriteFromCache(favoriteId: string): void {
    const combinedList: CombinedFavoritesList | undefined = getCombinedFavoritesFromCache();

    if (!combinedList) {
        return;
    }

    setCombinedFavoritesInCache({
        combinedFavorites: combinedList.combinedFavorites.filter(
            data => data.favoriteId !== favoriteId
        ),
    });
}

export function addFavoriteToCache(favoriteData: FavoriteData, newIndex?: number): void {
    let combinedList: CombinedFavoritesList | undefined = getCombinedFavoritesFromCache();

    if (!combinedList) {
        combinedList = {
            combinedFavorites: [],
        };
    }

    // check if item already in cache
    if (isFavoriteInCache(favoriteData.favoriteId)) {
        return;
    }

    if (newIndex) {
        combinedList.combinedFavorites.splice(newIndex, 0, {
            favoriteId: favoriteData.favoriteId,
            mailboxInfo: favoriteData.mailboxInfo,
        });
    } else {
        combinedList.combinedFavorites.push({
            favoriteId: favoriteData.favoriteId,
            mailboxInfo: favoriteData.mailboxInfo,
        });
    }

    //save to cache
    setCombinedFavoritesInCache({
        combinedFavorites: combinedList.combinedFavorites,
    });
}

export function swapFavoriteItemInCache(fromIndex: number, toIndex: number): void {
    const combinedList: CombinedFavoritesList | undefined = getCombinedFavoritesFromCache();

    if (!combinedList) {
        return;
    }

    moveDragAndDroppableItem(combinedList.combinedFavorites, fromIndex, toIndex);

    setCombinedFavoritesInCache({
        combinedFavorites: combinedList.combinedFavorites,
    });
}
